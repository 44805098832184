// Variable overrides
$themeColor: #405a8b;
$white: #ffffff;

$color1: #f9f9f9;
$color2: #e5e5e5;

$lg: 1199px;
$md: 991px;
$sm: 767px;
$xs: 575px;

/*--- Webkit ---*/
@mixin prefix($property, $parameters) {
    @each $prefix in -o-, -moz-, -ms-, -webkit-, "" {
        #{$prefix}#{$property}: $parameters;
    }
}
/*--- Media Query ---*/
@mixin responsive($media) {
    @if $media == lg {
        @media only screen and (max-width: $lg) {
            @content;
        }
    } @else if $media == md {
        @media only screen and (max-width: $md) {
            @content;
        }
    } @else if $media == sm {
        @media only screen and (max-width: $sm) {
            @content;
        }
    } @else if $media == xs {
        @media only screen and (max-width: $xs) {
            @content;
        }
    }
}

$base-size: 16;

@function rem($target, $context: $base-size) {
    @return ($target / $context) * 1rem;
}
